.pass-fail-button-top
{
    display: flex;
    justify-content: flex-end;
}  
.pass-fail-button-top .btn {
    margin: 3px;
}
.all-notifications-wrapper .card-header {
    border-bottom: 1px solid var(--border-color);
    text-align: start;
}
.pass-fail-button-top
{
    text-align: center;
}
.car-image-show-content
{
    /* margin-bottom: 15px; */
}
.tui-image-editor .tui-image-editor-rotate-btn {
    width: 40px;
    height: 40px;
}