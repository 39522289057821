.user_create_wrapper {
    padding-top: 100px;
}

.user_create_wrapper .nav.nav-tabs {
    border-bottom: 0px !important;
}

.user_create_wrapper .nav button.nav-link {
    position: relative;
    display: block;
    float: left;
    padding: 14px 15px 14px;
    border: 2px solid #c8ced3;
    /* border-top: 2px solid white; */
    border-radius: 0px;
    cursor: pointer;
    transition: all 200ms linear;
    background-color: transparent;
    background-color: var(--main-white);
    margin-right: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: 10px;
    font-weight: 700;
    transition-delay: 0.1s;
}

.user_create_wrapper .nav button.nav-link.active {
    border-color: var(--thm-color-red);
    border-top-color: var(--thm-color-red);
    color: var(--thm-color-red);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-color: transparent;
}

.user_create_wrapper .tab-content {
    /* margin-top: 0; */
    background-color: var(--main-white);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.theme_modal_user form .form-group,
.user_create_wrapper form .form-group {
    position: relative;
}

.theme_modal_user form .form-label,
.user_create_wrapper form .form-label {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 400;
    display: block;
    text-align: left;
}

.theme_modal_user .modal-header button.close_btn {
    background-color: #939393 !important;
    box-shadow: none;
    border: none;
    color: var(--main-white);
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    height: 30px;
    width: 30px;
    text-transform: capitalize;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: black;
    box-shadow: none !important;
    outline: none !important;
    opacity: 1 !important;
    border-radius: 5px;
    justify-content: center;
}

.theme_modal_user .modal-content {
    background-color: var(--main-white);
}

.theme_modal_user .modal-header button.close span {
    box-shadow: none !important;
    outline: none !important;
}

.theme_modal_user form .password_button,
.user_create_wrapper form .password_button {
    position: absolute;
    top: 35%;
    right: 2px;
}

.theme_modal_user form .password_button button,
.user_create_wrapper form .password_button button {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    min-width: 50px;
    min-height: 50px;
}

.theme_modal_user form button.cancel_btn,
.theme_modal_user form button.submit_button,
.user_create_wrapper form button.submit_button {
    background-color: var(--thm-color-red) !important;
    box-shadow: none;
    border: none;
    color: var(--main-white);
    padding: 10px;
    min-width: 130px;
    text-transform: capitalize;
    font-size: 16px;
    min-height: 45px;
}

.theme_modal_user form button.cancel_btn {
    background-color: #939393 !important;
}

.theme_modal_user form select,
.theme_modal_user form input,
.user_create_wrapper form select,
.user_create_wrapper form input {
    font-size: 15.5px;
    margin-bottom: 5px;
    min-height: 50px;
    padding: 10px;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #b3bbc2 !important;
    border-radius: 5px;
    color: #000;
    width: 100% !important;
}

.theme_modal_user form select,
.user_create_wrapper form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 30px;
}

.user_create_wrapper table tbody tr td,
.user_create_wrapper table thead tr th {
    padding: 0.75rem;
}

.user_create_wrapper table tbody tr td {
    padding: 0.40rem;
}

.user_create_wrapper table tbody tr td,
.user_create_wrapper table thead tr th {
    border: 1px solid #c8ced3 !important;
}

.user_create_wrapper table thead tr th:first-child {
    min-width: 80px;
    max-width: 80px;
    width: 80px;
}

.user_create_wrapper table thead tr th:last-child {
    min-width: 170px;
    max-width: 170px;
    width: 170px;
}

.user_create_wrapper table thead tr th:nth-child(2) {
    min-width: 200px;
    max-width: 200px;
    width: 200px;
}

.user_create_wrapper table thead tr th:nth-last-child(2) {
    min-width: 210px;
    max-width: 210px;
    width: 210px;
}

.user_create_wrapper table tbody tr td:last-child button {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    min-width: 40px;
    max-width: 40px;
    width: 40px;
    min-height: 40px;
    max-height: 40px;
    height: 40px;
    margin-left: 5px;
    color: var(--main-white);
    font-size: 15px !important;
    border-radius: 5px !important;
    border: 1px solid var(--thm-color-red) !important;
    background-color: var(--thm-color-red);
}

.user_create_wrapper table tbody tr td:last-child button i {
    color: var(--main-white);
}

.user_create_wrapper table tbody tr td:last-child button.edit_btn {
    background-color: transparent !important;
    color: var(--thm-color-red);
}

.user_create_wrapper table tbody tr td:last-child button.edit_btn i {
    color: var(--thm-color-red);
}

.user_create_wrapper table tbody tr td:last-child button.deactive_btn {
    /* background-color: transparent !important;
    color:var(--thm-color-red); */
}

.user_create_wrapper table tbody tr td:last-child button.active_btn {
    background-color: var(--green-color) !important;
    border-color: var(--green-color) !important;
    color: white;
}

.custom_card {
    background-color: var(--main-white);
    box-shadow: 0px 0px 8px rgba(156, 156, 156, 0.25);
    border: 0px;
    padding: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom:2.5px solid var(--thm-color-red);
}